import { useI18n } from "vue-i18n";
import { uncapitalizeFirstLetter } from "@/utils";

/**
 * Return i18n value based on the key (and the value) of a state object
 * @param {string} value
 * @param {string} prefix - i18n prefix matchinf the component collection
 * @returns {string} - undefined or i18n value
 */
export function useI18nValue(value, prefix) {
  const { t } = useI18n();

  if (!value) {
    return "";
  }
  const uncapitalizedValue = uncapitalizeFirstLetter(value);
  if (!uncapitalizedValue) {
    return "";
  }
  return t(`${prefix}.${uncapitalizeFirstLetter(value)}`);
}

/**
 * Convert features array into a string grouping and listing the solutions and the options
 * @param {Object[]}
 * @returns {string}
 */
export function stringifyFeatures(features, t) {
  let stringified = "";
  const options = [];
  const solutions = [];

  // create options and solutions arrays
  if (features) {
    features.forEach(feature => {
      if (feature.type === "option") {
        options.push(feature.name);
      } else {
        solutions.push(feature.name);
      }
    });
  }

  stringified = t("entitlements.solutions", solutions?.length);

  stringified += solutions.length ? ": " + solutions.join(", ") : "";

  stringified += " | ";

  stringified += t("entitlements.options", options.length);

  stringified += options.length ? ": " + options.join(", ") : "";

  return stringified;
}
