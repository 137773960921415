<template>
  <va-card>
    <va-card-title>{{ $t("entitlements.attributesTitle") }}</va-card-title>
    <va-card-content>
      <div
        v-for="attribute in localAttributes"
        :key="attribute.name"
        class="attribute-row"
      >
        <span class="attribute-name">{{ attribute.translatedName }}:</span>

        <!-- Show the input controls based on editMode and valueType -->
        <span v-if="!editMode" class="attribute-value">{{
          attribute.displayValue || attribute.value
        }}</span>

        <!-- String Input -->
        <va-input
          v-if="editMode && attribute.valueType === 'String'"
          v-model="attribute.value.value"
          :placeholder="attribute.translatedName"
          @update:model-value="
            updateEntitlementEditField(attribute.name, attribute.value.value)
          "
        />

        <!-- Boolean Dropdown -->
        <va-select
          v-if="editMode && attribute.valueType === 'Boolean'"
          v-model="attribute.value.value"
          :options="[
            { value: true, label: 'Yes' },
            { value: false, label: 'No' }
          ]"
          :track-by="'value'"
          :text-by="'label'"
          @update:model-value="
            updateEntitlementEditField(attribute.name, attribute.value.value)
          "
        />

        <!-- Selection Dropdown -->
        <va-select
          v-if="
            editMode &&
              attribute.valueType === 'Selection' &&
              attribute.values.length
          "
          v-model="attribute.value"
          :options="
            attribute.values.map(v => ({ value: v.value, label: v.name }))
          "
          :track-by="'value'"
          :text-by="'label'"
          @update:model-value="
            updateEntitlementEditField(
              attribute.name,
              attribute.value.value,
              attribute
            )
          "
        />

        <!-- Multi-Selection Dropdown -->
        <va-select
          v-if="editMode && attribute.valueType === 'MultiSelection'"
          v-model="attribute.value"
          :options="
            attribute.values.map(v => ({ value: v.value, label: v.name }))
          "
          multiple
          :track-by="'value'"
          :text-by="'label'"
          @update:model-value="
            updateEntitlementEditField(attribute.name, attribute.value.value)
          "
        />
      </div>
    </va-card-content>
  </va-card>
</template>

<script setup>
import { computed, ref, watch, onMounted, reactive } from "vue";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";
import { callFetchProductAttributes } from "@/api/licensing";

const props = defineProps({
  editMode: {
    type: Boolean,
    default: false
  }
});
function getLabel(options, inputKey) {
  const filterItem = options[inputKey];
  return filterItem.label
    ? t(filterItem.label)
    : t(`${props.i18nPrefix}.${inputKey}`);
}

const store = useStore();
const { t, te } = useI18n();
const productAttributes = ref([]);

// Reactive copy of attributes to work with locally
const localAttributes = reactive([]);

// Update the store when a field changes
const updateEntitlementEditField = (key, value, attribute) => {
  console.log("Updated attribute:", attribute);
  console.log(key, value);

  // Get the current entitlement edit state
  const entitlementEdit = store.state.licensing.entitlementEdit;

  // Find the attribute by name in the entitlementEdit attributes array
  const attributeIndex = entitlementEdit.attributes.findIndex(
    attr => attr.name === key
  );
  console.log(entitlementEdit.attributes);
  console.log(attributeIndex);

  if (attributeIndex !== -1) {
    // Update the value of the found attribute
    entitlementEdit.attributes[attributeIndex].value = value.value
      ? value.value
      : value;

    // Dispatch the updated attributes array to the store
    store.dispatch("licensing/updateEntitlementEditField", {
      key: "attributes",
      value: entitlementEdit.attributes
    });

    console.log("Updated entitlementEdit:", entitlementEdit);
  } else {
    console.error(`Attribute with key ${key} not found.`);
  }
};

// Fetch product attributes based on productId
const translatedAttributes = computed(() => {
  return store.state.licensing.entitlementEdit.attributes.map(attr => {
    const translationKey = `entitlements.${attr.name}`;
    const translatedName = te(translationKey) ? t(translationKey) : attr.name;

    const productAttribute = productAttributes.value.find(
      pa => pa.name === attr.name
    );
    let displayValue = attr.value;
    let valueType = "String"; // Default valueType

    if (productAttribute) {
      valueType = productAttribute.valueType || "String"; // Use productAttribute valueType if available
      if (productAttribute.values && Array.isArray(productAttribute.values)) {
        const matchingValue = productAttribute.values.find(
          v => v.value == attr.value
        );
        if (matchingValue) {
          displayValue = matchingValue.name;
        }
      } else if (valueType === "Boolean") {
        displayValue = attr.value ? "Yes" : "No"; // Display Yes/No for Boolean
      }
    }

    return {
      ...attr,
      translatedName,
      displayValue,
      valueType, // Add valueType to the returned object
      value: {
        value: attr.value,
        label: displayValue
      },
      values: productAttribute?.values || [] // Add values for dropdowns if applicable
    };
  });
});

// Sync store attributes to local reactive attributes
watch(
  translatedAttributes,
  newAttrs => {
    newAttrs.forEach(attr => {
      const existingAttr = localAttributes.find(a => a.name === attr.name);
      if (existingAttr) {
        Object.assign(existingAttr, attr);
      } else {
        localAttributes.push(attr);
      }
    });
  },
  { immediate: true }
);

onMounted(async () => {
  const productId = store.state.licensing.entitlementEdit.productId;
  await loadProductAttributes(productId);
});

const loadProductAttributes = async productId => {
  try {
    const response = await callFetchProductAttributes(productId);
    productAttributes.value = response.data.items;
  } catch (error) {
    console.error("Failed to fetch product attributes:", error);
  }
};
</script>

<style scoped>
.attribute-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.attribute-name {
  font-size: 0.9em;
  font-weight: 700;
  color: var(--va-gray);
  text-transform: uppercase;
}

.attribute-value {
  font-family: monospace;
  font-size: 0.9rem;
}
</style>
