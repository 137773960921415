<template>
  <va-modal
    v-model="state.isOpen"
    hide-default-actions
    :title="$t(`${licensingObject}s.editOwnerModal.title`)"
  >
    <slot>
      <div class="bodyContainer">
        <!-- v-if because organization is an option only for entitlements -->
        <va-button-toggle
          v-if="state.isEntitlement"
          outline
          class="mb-3"
          v-model="state.entityType"
          :options="ownerTypeOptions"
        />

        <!-- Organization -->
        <div v-if="state.entityType === 'Organization'" class="inputContainer">
          <va-input
            class="my-2"
            v-model="state.organizationID"
            :label="$t(`${licensingObject}s.editOwnerModal.organizationID`)"
            :placeholder="
              $t(`${licensingObject}s.editOwnerModal.theOrganizationID`)
            "
            :error="state.organizationIDOk === false"
            :error-messages="
              $t(`${licensingObject}s.editOwnerModal.wrongOrganizationID`)
            "
            :success="state.organizationIDOk"
            @change="checkId('organizations', 'organizationID')"
          />
        </div>

        <!-- User -->
        <!-- v-else to also match undefined entityType -->
        <template v-else>
          <div class="inputContainer">
            <va-input
              class="my-2"
              :class="!state.isEntitlement && 'w-55'"
              v-model="state.userID"
              :disabled="state.isAnonymous"
              :label="$t(`${licensingObject}s.editOwnerModal.userID`)"
              :placeholder="
                $t(`${licensingObject}s.editOwnerModal.theUserID`)
              "
              :error="state.userIDOk === false"
              :error-messages="
                $t(`${licensingObject}s.editOwnerModal.wrongUserID`)
              "
              :success="state.userIDOk"
              @change="checkId('users', 'userID')"
            />
            <template v-if="!state.isEntitlement">
              <input
                id="anonymousLicense"
                class="my-3"
                type="checkbox"
                v-model="state.isAnonymous"
              />
              <label class="my-3 ml-2" for="anonymousLicense">
                {{ $t(`${licensingObject}s.editOwnerModal.anonymous`) }}
              </label></template
            >
          </div>
        </template>
      </div>
    </slot>
    <template #footer>
      <va-button flat outline class="mr-1" @click="emit('cancel')">
        {{ $t("buttons.cancel") }}
      </va-button>
      <va-button
        class="ml-1"
        :disabled="
          !state.organizationIDOk && !state.userIDOk && !state.isAnonymous
        "
        @click="applyChange()"
      >
        {{ $t("buttons.validate") }}
      </va-button>
    </template>
  </va-modal>
</template>

<script setup>
import { computed, reactive, watch } from "vue";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
import { callFetchUser, callFetchOrganization } from "@/api/identity";

const { t } = useI18n();
const store = useStore();

const props = defineProps({
  isOpen: {
    type: Boolean,
    required: true
  },
  entityType: {
    type: String,
    default: "User"
  },
  licensingObject: {
    type: String,
    required: true
  },
  // Necessary false for entitlements
  isAnonymous: {
    type: Boolean,
    default: false
  }
});

const state = reactive({
  isOpen: computed(() => props.isOpen),
  isEntitlement: props.licensingObject === "entitlement",
  entityType: props.entityType,
  isAnonymous: props.isAnonymous,

  // organizationID & accountID aren't mutualized as we intend to improve UX for user owner change in the future
  // the future feature for users will be different from the org's feature
  organizationID: "",
  organizationIDOk: undefined,
  userID: "",
  userIDOk: undefined
});

// we need a watcher because va-modal doesn't support v-if
// so the modal is mounted at the init of the app and the props are set at this moment
// so we add a watcher to init/reinit properly the state variables based on properties
watch(
  () => props.isOpen,
  (isOpen, prevIsOpen) => {
    if (isOpen) {
      state.entityType = props.entityType;
      state.isAnonymous = props.isAnonymous;
    } else if (prevIsOpen) {
      // Reinit properties
      state.organizationID = "";
      state.organizationIDOk = undefined;
      state.userID = "";
      state.userIDOk = undefined;
    }
  }
);

const ownerTypeOptions = [
  { label: t("entityType.organization"), value: "Organization" },
  { label: t("entityType.user"), value: "User" }
];

const emit = defineEmits(["cancel", "validate"]);

/**
 * Set state[`${id}Ok`] to true if the account exists, false if not, undefined if it's falsy
 * @param {'accounts'|'organizations'} apiObject
 * @param {string} id
 */
const checkId = async (apiObject, id) => {
  if (state[id]) {
    try {
      if (apiObject === "users") {
        await callFetchUser(state[id]);
      } else if (apiObject === "organizations") {
        await callFetchOrganization(state[id]);
      } else {
        // should not happen
        throw new Error();
      }
      state[`${id}Ok`] = true;
    } catch (error) {
      state[`${id}Ok`] = false;
    }
  } else {
    state[`${id}Ok`] = undefined;
  }
};

/**
 * Call the right change function depending on props.licensingObject
 */
const applyChange = () => {
  switch (props.licensingObject) {
    case "assignment":
      changeAssignee();
      break;
    case "entitlement":
      changeOwner();
      break;
    default:
      break;
  }
};

/**
 * Update assignment's assignee in the database and in the store
 */
const changeAssignee = async () => {
  await store.dispatch("licensing/updateAssignmentAssignee", {
    assignmentId: store.state.licensing.assignment.id,
    assigneeId: state.entityType === "Organization"
      ? state.organizationID
      : state.userID,
  });

  // close the modal even if the request fails
  emit("validate");
};

/**
 * Update entitlement's owner in the database and in the store
 */
const changeOwner = async () => {
  store.dispatch("licensing/updateEntitlementEditField", {
    key: "ownerId",
    value: state.entityType === "Organization"
      ? state.organizationID
      : state.userID,
  });

  // close the modal even if the request fails
  emit("validate");
};
</script>

<style lang="scss" scoped>
.bodyContainer {
  display: flex;
  flex-direction: column;
  align-items: center;

  min-width: 520px;
}

.inputContainer {
  display: flex;
  justify-content: center;
  width: 100%;

  > .va-input-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 0 1rem;

    &.w-55 {
      width: 55%;
    }
  }
}
</style>
