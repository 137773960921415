export const LICENSE_TYPES = ["Floating", "MachineLocked", "UserLocked"];
export const OFFLINE_ACTIVATION_MODE = ["Disabled", "Lax", "Strict"];
export const ENTITLEMENTS_ORIGIN = [
  "Agent",
  "Imported",
  "Order",
  "AutomatedTrialRequest",
  "Giveaway"
];

// TO DO: _desc could be handled in a smarter way
export const USER_PROFILE_FIELDS = [
  "firstName",
  "lastName",
  "locale",
  "country",
  "phoneNumber",
  "firstName_desc",
  "lastName_desc",
  "locale_desc",
  "country_desc",
  "phoneNumber_desc"
];

export const ACTIVATIONS_LOGS_TYPE_COLORS = {
  ActivationCreated: "success",
  ActivationUpdated: "warning",
  ActivationDeleted: "danger"
};
