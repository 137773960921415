<template>
  <va-modal
    v-model="state.isOpen"
    hide-default-actions
    :title="$t('entitlements.deleteOwnerModal.title')"
  >
    <slot>
      <span>{{ $t("entitlements.deleteOwnerModal.explanation") }}</span>
    </slot>

    <template #footer>
      <va-button flat outline class="mr-1" @click="emit('cancel')">
        {{ $t("buttons.cancel") }}
      </va-button>
      <va-button class="ml-1" color="danger" @click="deleteEntitlementOwner()">
        {{ $t("buttons.validate") }}
      </va-button>
    </template>
  </va-modal>
</template>

<script setup>
import { computed, reactive } from "vue";
import { useStore } from "vuex";

const store = useStore();

const props = defineProps({
  isOpen: {
    type: Boolean,
    default: false
  }
});

const state = reactive({
  isOpen: computed(() => props.isOpen)
});

const emit = defineEmits(["cancel", "validate"]);

/**
 * Delete the entitlement owner.
 */
const deleteEntitlementOwner = async () => {
  store.dispatch("licensing/updateEntitlementEditField", {
    key: "owner",
    value: null
  });

  // close the modal even if the request fails
  emit("validate");
};
</script>
