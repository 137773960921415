<template>
  <va-modal
    v-model="state.isOpen"
    hide-default-actions
    :title="$t('redeemUrlModal.title')"
  >
    <slot>
      <div class="bodyContainer">
        <div v-if="state.isGenerating" class="loading-message">
          Generating URL<span class="dots">...</span>
        </div>
        <div v-else class="url-container">
          <div class="redeem-url-label">{{ fullRedeemUrl }}</div>
          <button-clipboard-copy :field="$t('redeemUrlModal.redeemUrl')" :value="fullRedeemUrl" />
        </div>
      </div>
    </slot>
    <template #footer>
      <va-button flat outline class="mr-1" @click="emit('cancel')" :disabled="state.isUpdating">
        {{ $t("buttons.close") }}
      </va-button>
      <va-button v-if="!state.isGenerating" class="ml-1" @click="copyRedeemUrl" :disabled="state.isUpdating">
        {{ $t("buttons.copyUrl") }}
      </va-button>
    </template>
  </va-modal>
</template>
  
  <script setup>
  import ButtonClipboardCopy from "@/components/ButtonClipboardCopy.vue";
  import { computed, reactive, watchEffect,inject } from 'vue';
  import { callFetchRedeemToken } from '@/api/licensing';
  import { useI18n } from "vue-i18n";
  
  const { t } = useI18n();
  const toasts = inject("toasts") ;
  const props = defineProps({
    isOpen: {
      type: Boolean,
      required: true
    },
    entitlementId: {
      type: String,
      required: true
    }
  });
  
  const state = reactive({
    isOpen: computed(() => props.isOpen),
    redeemUrl: '',
    isGenerating: true,
    isUpdating: false
  });
  
  const baseUrl = process.env.VUE_APP_REDEEM_URL;
  
  const fetchRedeemUrl = async () => {
    state.isGenerating = true;
    try {
      const resp = await callFetchRedeemToken(props.entitlementId);
      if (resp?.data) {
        state.redeemUrl = `${baseUrl}${resp.data.redeemToken}`;
      }
    } catch (err) {
      console.error('Error fetching redeem URL:', err);
    } finally {
      state.isGenerating = false;
    }
  };
  
  watchEffect(() => {
    if (state.isOpen) {
      fetchRedeemUrl();
    }
  });
  
  const fullRedeemUrl = computed(() => state.redeemUrl);
  
  const emit = defineEmits(['cancel']);
  
  const copyRedeemUrl = () => {
    navigator.clipboard.writeText(fullRedeemUrl.value);
    toasts?.info(t("redeemUrlModal.redeemCopyMsg"));

  };
  </script>
  
  <style scoped>
  .bodyContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    min-width: 520px;
    padding-left: 3rem;
    line-height: 2rem;
  }
  .url-container {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
    width: 100%;
  }
  .redeem-url-label {
    flex-grow: 1;
    margin-right: 0.5rem;
    width: 95%;
    word-wrap: break-word; 
    white-space: pre-wrap; 
    font-weight: normal; 
    border: 1px solid #ddd; 
    padding: 0.5rem; 
    background-color: #f9f9f9; 
  }
  .loading-message {
    font-size: 1rem;
    font-weight: normal;
    color: #555;
  }
  .dots::after {
    content: '';
    display: inline-block;
    width: 1rem;
    height: 1rem;
    animation: dots 1.5s steps(5, end) infinite;
  }
  
  @keyframes dots {
    0%, 20% {
      content: '';
    }
    40% {
      content: '.';
    }
    60% {
      content: '..';
    }
    80%, 100% {
      content: '...';
    }
  }
  </style>
  