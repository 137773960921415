import http from "@/api/http";

/**
 * Fetch activations events
 * @param {Object} payload
 * @return {Promise<Object>}
 */
export const callFetchActivationsEvents = async payload => {
    return await http.get("/api/audithub/activations", payload);
  };
  
  /**
   * Fetch activations events detail
   * @param {Object} payload
   * @return {Promise<Object>}
   */
  export const callFetchActivationEvent = async eventId => {
    return await http.get(`/api/audithub/activations/${eventId}`);
  };
