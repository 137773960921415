<template>
  <va-modal
    v-model="state.isOpenSecondModal"
    hide-default-actions
    :title="$t('activations.showActivationsEventsDetailModal.title')"
  >
    <slot></slot>
    <div class="mb-3">
      <h4 class="va-h4">{{ $t('activations.showActivationsEventsDetailModal.details') }}</h4>
      <table class="kv-table">
        <tr>
          <td><label>{{ $t('activations.showActivationsEventsDetailModal.activationId') }}</label></td>
          <td>{{ event.data.activationId }}</td>
        </tr>
        <tr>
          <td><label>{{ $t('activations.showActivationsEventsDetailModal.licenseId') }}</label></td>
          <td>{{ event.data.licenseId }}</td>
        </tr>
        <tr>
          <td><label>{{ $t('activations.showActivationsEventsDetailModal.entitlementId') }}</label></td>
          <td>{{ event.data.entitlementId }}</td>
        </tr>
        <tr>
          <td><label>{{ $t('activations.showActivationsEventsDetailModal.createdAt') }}</label></td>
          <td>{{ formatDate(event.data.createdAt) }}</td>
        </tr>
        <tr>
          <td><label>{{ $t('activations.showActivationsEventsDetailModal.triggeredAt') }}</label></td>
          <td>{{ formatDate(event.triggeredAt) }}</td>
        </tr>
      </table>
      
      <h4 class="va-h4">{{ $t('activations.showActivationsEventsDetailModal.state') }}</h4>
      <table class="kv-table">
        <tr>
          <td><label>{{ $t('activations.showActivationsEventsDetailModal.method') }}</label></td>
          <td>{{ event.data.state.method }}</td>
        </tr>
        <tr>
          <td><label>{{ $t('activations.showActivationsEventsDetailModal.lastRefreshedAt') }}</label></td>
          <td>{{ formatDate(event.data.state.lastRefreshedAt) }}</td>
        </tr>
        <tr>
          <td><label>{{ $t('activations.showActivationsEventsDetailModal.hostName') }}</label></td>
          <td>{{ event.data.state.hostName }}</td>
        </tr>
        <tr>
          <td><label>{{ $t('activations.showActivationsEventsDetailModal.os') }}</label></td>
          <td>{{ event.data.state.os }}</td>
        </tr>
        <tr>
          <td><label>{{ $t('activations.showActivationsEventsDetailModal.osVersion') }}</label></td>
          <td>{{ event.data.state.osVersion }}</td>
        </tr>
        <tr>
          <td><label>{{ $t('activations.showActivationsEventsDetailModal.vmName') }}</label></td>
          <td>{{ event.data.state.vmName || 'N/A' }}</td>
        </tr>
        <tr>
          <td><label>{{ $t('activations.showActivationsEventsDetailModal.productVersion') }}</label></td>
          <td>{{ event.data.state.productVersion || 'N/A' }}</td>
        </tr>
        <tr>
          <td><label>{{ $t('activations.showActivationsEventsDetailModal.aliasName') }}</label></td>
          <td>{{ event.data.state.aliasName || 'N/A' }}</td>
        </tr>
        <tr>
          <td><label>{{ $t('activations.showActivationsEventsDetailModal.machineFingerprint') }}</label></td>
          <td>{{ event.data.state.machineFingerprint || 'N/A' }}</td>
        </tr>
        <tr>
          <td><label>{{ $t('activations.showActivationsEventsDetailModal.internalData') }}</label></td>
          <td>{{ event.data.state.internalData || 'N/A' }}</td>
        </tr>
      </table>
      <template v-if="event.data.state.user">
        <h4 class="va-h4">{{ $t('activations.showActivationsEventsDetailModal.userDetails') }}</h4>
        <table class="kv-table">
          <tr>
            <td><label>{{ $t('activations.showActivationsEventsDetailModal.userId') }}</label></td>
            <td>
              <router-link :to="{ path: userLink(event.data.state.user.id) }">
                {{ event.data.state.user.id }}
              </router-link>
              <button-clipboard-copy :field="$t('activations.showActivationsEventsDetailModal.userId')" :value="event.data.state.user.id" />
            </td>
          </tr>
          <tr>
            <td><label>{{ $t('activations.showActivationsEventsDetailModal.userName') }}</label></td>
            <td>{{ event.data.state.user.name }}</td>
          </tr>
          <tr>
            <td><label>{{ $t('activations.showActivationsEventsDetailModal.userEmail') }}</label></td>
            <td>
              {{ event.data.state.user.email }}
              <button-clipboard-copy :field="$t('activations.showActivationsEventsDetailModal.userEmail')" :value="event.data.state.user.email" />
            </td>
          </tr>
        </table>
      </template>
    </div>
    <template #footer>
      <va-button class="mr-1" @click="emit('cancel')">
        {{ $t("buttons.ok") }}
      </va-button>
    </template>
  </va-modal>
</template>

<script setup>
import { computed, reactive } from "vue";
import ButtonClipboardCopy from "@/components/ButtonClipboardCopy.vue";

const props = defineProps({
  isOpen: {
    type: Boolean,
    required: true
  },
  event: {
    type: Object,
    required: true
  }
});


const state = reactive({
  isOpenSecondModal: computed(() => props.isOpen),
  event: computed(() => props.event)
});

const formatDate = (date) => {
  if (!date) {return "";}
  const options = { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric' };
  return new Date(date).toLocaleDateString(undefined, options);
};

const userLink = (userId) => {
  return `/admin/identity/users/${userId}`;
};

const emit = defineEmits(["cancel"]);
</script>

<style scoped>
.kv-table label:after {
  content: ":";
}

.kv-table td {
  padding: 0.5rem;
}

.kv-table label {
  font-size: 0.9em;
  font-weight: 700;
  color: var(--va-gray);
  text-transform: uppercase;
}
</style>
