<template>
  <va-modal v-model="state.isOpen" hide-default-actions :title="$t('activations.showActivationsEventsModal.title')" size="large">
    <slot>
      <span class="d-flex mb-2">{{ $t("activations.showActivationsEventsModal.explanation") }}</span>
    </slot>
    <activations-events-table />
    <template #footer>
      <va-button class="mr-1" @click="emit('cancel')">
        {{ $t("buttons.ok") }}
      </va-button>
    </template>
  </va-modal>
</template>
<script setup>
import { computed, reactive } from "vue";
import ActivationsEventsTable from "@/components/activations/ActivationsEventsTable.vue";

const props = defineProps({
  isOpen: {
    type: Boolean,
    required: true
  }
});

const state = reactive({
  isOpen: computed(() => props.isOpen)
})

const emit = defineEmits(["cancel"]);

</script>